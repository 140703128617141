import { CDN_URL_ICONS, CDN_URL_IMAGES } from '@/configs';
import { GetServerSideProps } from 'next';
import { setReferralCodeOnServerSide } from '@/utils/referral';
import Lab from '@/modules/Lab';

const NbcLab = () => {
  return <Lab />;
};

export const getServerSideProps: GetServerSideProps<any> = async ctx => {
  const code = ctx?.query?.referral;

  if (code) {
    setReferralCodeOnServerSide(ctx, `${code}`);
  }

  return {
    props: {
      seoInfo: {
        title: 'New Bitcoin Labs',
        description:
          'A Bitcoin-focused fund that backs bold builders building the future of Bitcoin.',
        image: `${CDN_URL_IMAGES}/metadata-lab.jpeg`,
        favicon: `/nbl-favicon-svg.svg`,
      },
    },
  };
};

export default NbcLab;
